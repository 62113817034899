import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  setGlass,
  getGlass,
  getGlassDropDowns,
  setGlassDropDowns,
  getSaveGlass,
  getSaveGlassSuccess,
  getSaveGlassError,
  getUpdateGlass,
  getUpdateGlassSuccess,
  getUpdateGlassError,
  setBrandDropDowns,
  getBrandDropDowns
} from "../reducers/glassSlice";

async function fetchGlassesAPI(payload) {
  const storeId = "";
  const products = axios
    .get('/client/inventory/inventorynongrouped', {
      params: {
        limit: 15,
        page: payload,
        storeId: storeId,
        search: "",
        filter: {},
        sort: { _created_at: -1 },
      }
    })
    .then((response) => response.data.data);
  return products;
}
export function* fetchGlasses(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchGlassesAPI, payload);
    yield put(setGlass(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchGlassesDropdownAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token") 
}; 
  const apiURL = process.env.REACT_APP_API_URL + `/v1/eyeplay/client/brand/getv1`;
  const products = axios
    .get(apiURL, {
      headers: headers,
    })
    .then((response) => response.data.data);
  return products;
}

export function* fetchGlassesDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchGlassesDropdownAPI, payload);
    yield put(setGlassDropDowns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveGlassesAPI(payload) {
  const products = axios
    .post('/client/inventory/createinventory', payload)
    .then((response) => response);

  return products;
}

export function* saveGlasses(action) {
  try {
    const { payload } = action;
    const response = yield call(saveGlassesAPI, payload);
    yield put(getSaveGlassSuccess(response.data));
  } catch (error) {
    yield put(getSaveGlassError(error.response.data.message));
  }
}


async function updateGlassesAPI(payload) {
  const products = axios
    .post('/client/inventory/inventoryupdate', payload)
    .then((response) => response);

  return products;
}

export function* updateGlasses(action) {
  try {
    const { payload } = action;
    const response = yield call(updateGlassesAPI, payload);
    yield put(getUpdateGlassSuccess(response.data));
  } catch (error) {
    yield put(getUpdateGlassError(error.response.data.message));
  }
}


async function fetchBrandDropdownAPI(payload) {
  const products = axios
    .get('/client/brand/fetch', {
      params: {
        filter: {},
        limit: 1000,
        page: 0,
        sort: { "created_at": -1 }
      }
    })
    .then((response) => response.data.data);
  return products;
}

export function* fetchBrandDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchBrandDropdownAPI, payload);
    yield put(setBrandDropDowns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* glassSaga() {
  yield takeLatest(getGlass.type, fetchGlasses);
  yield takeLatest(getGlassDropDowns.type, fetchGlassesDropdown);
  yield takeLatest(getBrandDropDowns.type, fetchBrandDropdown);
  yield takeLatest(getSaveGlass.type, saveGlasses);
  yield takeLatest(getUpdateGlass.type, updateGlasses);
}
